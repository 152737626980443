.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 1rem;
}

.navbar__logo {
  font-size: 1.5rem;
}

.navbar__nav ul {
  list-style-type: none;
  display: flex;
  gap: 2rem;
}

.navbar__nav li {
  margin-right: 1rem;
}

.navbar__nav a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar__nav a:hover {
  color: lightgray;
}

.navbar__actions {
  display: flex;
  gap: 1rem;
}

.search-button, .cart-button, .profile-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-button:hover, .cart-button:hover, .profile-button:hover {
  color: lightgray;
}

.search-input-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  visibility: hidden;
  height: 0;
  transition: all 0.3s ease;
}

.search-input-container.active {
  visibility: visible;
  height: 80px;
}

.search-input {
  width: 0;
  padding: 0.5rem;
  border: none;
  background-color: #333;
  color: white;
  font-size: 1rem;
  outline: none;
  transition: width 0.3s ease;
}

.search-input.active {
  width: 20rem; /* Adjust width as needed */
}


