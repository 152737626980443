/* Container for the slider */
.custom-slider-container {
    position: relative;
    width: 90%; /* 90% width on big screens */
    max-width: 1200px; /* Optional: Set a max-width for very large screens */
    margin: 0 auto;
    padding: 20px;
    overflow: visible; /* Allow arrows to be fully visible */
  }

/* Individual slide */
.custom-slide {
  padding: 10px;
  text-align: center;
}

/* Slide images */
.slide-image {
  width: 350px; /* Fixed width for images */
  height: auto; /* Auto height to maintain aspect ratio */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Dots (pagination) */
.slick-dots {
  position: absolute;
  bottom: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 0;
  transition: background-color 0.3s ease;
}

.slick-dots li.slick-active button {
  background-color: #333;
}

/* Custom arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: transparent; /* Remove background */
  border: none;
  color: black; /* Set arrow color to black */
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: color 0.3s ease; /* Transition for color change */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev:hover,
.slick-next:hover {
  color: rgba(0, 0, 0, 0.8); /* Slightly darker black on hover */
}

.slick-prev {
  left: -50px; /* Adjust position for left arrow */
}

.slick-next {
  right: -50px; /* Adjust position for right arrow */
}

.slick-prev::before,
.slick-next::before {
  display: none; /* Hide default arrows */
}



/* Responsive adjustments */
@media (max-width: 1024px) {
  .custom-slider-container {
    width: 95%; /* Adjust width for medium screens */
  }

  .slide-image {
    width: 100%; /* Make images responsive on smaller screens */
    max-width: 500px; /* Ensure images don't exceed 500px */
  }
}

@media (max-width: 768px) {
  .custom-slider-container {
    width: 100%; /* Full width on small screens */
  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -30px;
  }
}

@media (max-width: 480px) {
  .slick-prev {
    left: -20px;
  }

  .slick-next {
    right: -20px;
  }
}


/* Custom dots container */
.custom-dots {
    position: absolute;
    bottom: 10px; /* Adjust this value to position dots properly */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Custom dot */
  .custom-dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Active dot */
  .custom-dot.active {
    background-color: #333;
  }