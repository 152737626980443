.footermain {
    width: 100%;
    background-color: white;
    height: auto;
    padding: 50px 0px;
}

.insidefootercontent {
    width: 97%;
    height: 600px;
    background-color: #E6E5E5;
    margin-left: 1.5%;
    border-radius: 50px;
    display: flex;
    padding: 30px;
    gap: 40px;
    position: relative;
}

.footerimg {
    width: 35%;
    height: 95%;
    margin-top: 1%;
    overflow: hidden;
}

.footerimg img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover; 
}

.footercontents {
    width: 60%;
    margin-top: 20px;
    position: relative;
}

.logobellofooter {
    position: absolute;
    right: 0;
    width: 200px; 
    margin-top: -40px;
    height: 200px; 
}

.textinfooter{
    margin-top: 135px;
    margin-left: 100px;
}

.textinfootermob{
    margin-left: 100px;
}

.socialsfootermob{
    display: flex;
    gap: 20px;
}


.logobellofooter img {
    width: 100%; 
    height: 100%;
    object-fit: contain; 
}

.twofooter{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 100px;
    position: relative;
    padding-bottom: 112px;
}

.twofooter p {
    margin-top: -10px;
    font-weight: 400;
    transition: text-decoration 0.5s;
    text-decoration: none;
}

.getdirection{
    display: flex;
    align-items: center;
    gap: 5px;
}

.twofooter p:hover {
    cursor: pointer;
    text-decoration: underline; 
}

.bottomfooterprod{
    margin-top: 20px;
}

.socialsfooter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
}

.footericons{
    transition: 0.3s;
}

.footericons:hover{
    transform: scale(1.11);
    cursor: pointer;
}

.copfooter {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center; 
}

.copfooter{
    font-size: 14px;
    margin-bottom: 10px;
}


@media screen and (max-width: 1430px){
    .twofooter{
        gap: 50px;
    }
}

.mobilefooter{
    display: none;
}
@media screen and (max-width: 1253px){
    .textinfooter{
        margin-left: 50px;
    }

  
}


@media screen and (max-width: 735px){
    .footermain{
        display: none;
    }
    .mobilefooter{
        display: flex;
        flex-direction: column;
        width: 98%;
        margin-left: 1%;
        background-color: #E6E5E5;
        height: auto;
        margin-top: 50px;
        border-radius: 10px;
        
    }

    .copfootermobile{
        padding-left: 5%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .textinfootermob{
        margin-top: 50px;
    }

    .twofootermob{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 20px;
    }

    .footerimgmob{
        width: 90%;
        height: 70vh;
        margin-top: 10%;
        margin-left: 5%;
    }

    .footerimgmob img{
        width: 100%;
        height: 100%;
        border-radius: 30px;
    }
}


@media screen and (max-width: 624px){
    .twofootermob{
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (max-width: 440px){
    .twofootermob{
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
}

@media screen and (max-width: 500px){
    .footerimgmob{
        height: 60vh;
    }
}

@media screen and (max-width: 380px){
    .footerimgmob{
        height: 50vh;
    }
}

.socalsclass{
    color: black;
}



@media screen and (max-width: 1105px){
    .twofooter{
        grid-template-columns: repeat(2,1fr);
    }

    .insidefootercontent{
        height: auto;
    }

    .footerimg {
        width: 55%;
        margin-top: 10%;
    }

}

@media screen and (max-width: 860px){
    .twofooter{
        grid-template-columns: repeat(1,1fr);
    }

    .footerimg {
        width: 75%;
        margin-top: 20%;
    }
}

@media screen and (max-width: 723px){
    .insidefootercontent{
        flex-direction: column-reverse;
        align-items: center;
    }

    .logobellofooter{
        margin-top: -230%;
        margin-right: -40%;
    }

    .socialsfooter{
        width: 80%;
    }

    .textinfooter{
        margin-top: 6%;
    }

    .footercontents{
        width: 80%;
    }

    .footerimg{
        margin-top: 2%;
    }
}