.login-container {
  width: 100%;
  height: 200vh;
  background-color: whitesmoke;
}

.insidelogin {
  width: 50%;
  margin-left: 25%;
}

.logoimg {
  margin: 0;
  padding: 0;
}

.imgdescall {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgdescall h2 {
  margin-top: -50px;
}

.placeholder-label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #aaa;
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 1rem;
}

.fc:focus + .placeholder-label,
.placeholder-label.active {
  top: -10px;
  left: 10px;
  font-size: 0.75rem;
  color: #333;
  background: #fff;
  padding: 0 5px;
}

.pwch {
  color: grey;
  margin-top: -3%;
}

.invalid-text {
  color: red;
}

.icd {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input-checkbox {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.checkbox-label {
  font-size: 1rem;
}

.loginpage {
  width: 100%;
  padding-left: 15%;
}

.fgg {
  width: 85%;
  margin-bottom: 1rem;
}

.fcc {
  width: 100%;
  min-width: 250px;
  padding: 2%;
  max-width: 100%;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.fcc.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  margin-left: 11%;
}

.loginbtn {
  width: 85%;
  padding: 6px 0;
  border-radius: 6px;
  background-color: black;
  color: white;
  font-size: 16px;
  transition: 0.3s;
}

.loginbtn:hover {
  background-color: rgb(47, 46, 46);
}

@media (max-width: 790px) {
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
}

@media (max-width: 733px) {
  h3 {
    font-size: 17px;
  }
  p {
    font-size: 12px;
  }
}

@media (max-width: 695px) {
  .logoimg {
    width: 200px;
    height: 250px;
  }
  h3 {
    font-size: 17px;
  }
  p {
    font-size: 12px;
  }
}

@media (max-width: 665px) {
  .insidelogin {
    width: 70%;
    margin-left: 15%;
  }
}

@media (max-width: 500px) {
  .insidelogin {
    width: 75%;
    margin-left: 12%;
  }
}

.forminput{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}


.forgotbutton{
  width: 8%;
  padding: 6px 0;
  border-radius: 6px;
  background-color: black;
  color: white;
  font-size: 14px;
  transition: 0.3s;
}

.forgotbutton:hover {
  background-color: rgb(47, 46, 46);
}



@media (max-width: 450px) {
  .insidelogin {
    width: 100%;
    margin-left: 0;
  }

  .forminput{
    flex-direction: column;
    justify-content: center;
  }

  .forminput .form-control{
    width: 100%;
  }

  .forgotbutton{
    width: 95%;
  }
}

