@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

/* Dashboard.css */
.container-fluid {
  background-color: #f0f2f5; /* Soft background color */
  padding: 20px;
  border-radius: 12px; /* Rounded corners for the container */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.row {
  margin: 0;
}

.col-md-3 {
  padding: 0;
}

.col-md-9 {
  padding: 0;
}

.card {
  background-color: #ffffff; /* Clean white background for cards */
  border: none; /* Remove border for a clean look */
  border-radius: 12px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  padding: 20px; /* Adequate padding inside the card */
  margin-bottom: 20px; /* Space between cards */
}

.card h3 {
  color: #333333; /* Dark text for readability */
  font-size: 20px; /* Larger font size for headers */
  font-weight: 600; /* Bold text */
  margin-bottom: 10px; /* Space below headers */
}

.user-info {
  background-color: #e9ecef; /* Light background for user info section */
  padding: 15px;
  border-radius: 8px; /* Rounded corners for the user info section */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Slight shadow */
}

.user-info h3 {
  margin: 0; /* Remove margin for headers in user info */
  font-size: 18px; /* Adjust font size */
}

@media (max-width: 767px) {
  .col-md-3 {
    display: none; /* Hide sidebar on small screens */
  }

  .col-md-9 {
    width: 100%;
  }

  .card {
    width: 100%; /* Full width for cards on small screens */
  }
}









/* Profile.css */
.container-fluid {
  background-color: #f9f9f9; /* Light gray background for the container */
  border-radius: 12px; /* Rounded corners for the container */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  padding: 20px;
}

.form-container {
  background-color: #ffffff; /* White background for the form container */
  border-radius: 8px; /* Rounded corners for the form */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for form */
  padding: 20px;
}

.title {
  font-size: 24px; /* Larger font size for the title */
  font-weight: 600; /* Bold title */
  margin-bottom: 20px; /* Space below the title */
  color: #333; /* Dark text color */
}

.mb-3 {
  margin-bottom: 15px; /* Space between form fields */
}

.form-control {
  border-radius: 6px; /* Rounded corners for inputs */
  border: 1px solid #ced4da; /* Light border */
  padding: 10px; /* Padding inside input fields */
}

.btn-primary {
  background-color: #007bff; /* Primary button color */
  border: none; /* Remove border */
  border-radius: 6px; /* Rounded corners for button */
  padding: 10px 20px; /* Padding inside button */
  color: #ffffff; /* White text color */
  font-size: 16px; /* Font size for button text */
  cursor: pointer; /* Pointer cursor on hover */
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker color on hover */
}

.updatebtn{
  background-color: black;
  color: white;
  padding: 10px 125px;
  border: none;
  border-radius: 5px;
  transition: 0.3s;
}

.updatebtn:hover{
  background-color: rgb(53, 53, 53);
}
@media (max-width: 767px) {
  .col-md-3 {
    display: none; /* Hide sidebar on small screens */
  }

  .col-md-8 {
    width: 100%; /* Full width on smaller screens */
  }
}

/* src/About.css */
.wholeaboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.belolgoo img {
  border-radius: 10px;
}

.about-us-content {
  margin: 20px;
}

.blackline {
  height: 7px;
  background: linear-gradient(to right, rgb(46, 46, 46), rgb(255, 255, 255));
  width: 0;
  margin: 0 auto;
  animation: expandLine 2s forwards;
}

@keyframes expandLine {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.additional-sections {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
}

.section {
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

.text-image {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row; /* Default direction */
}

.text-image.reverse {
  flex-direction: row-reverse; /* Reversed direction */
}

.text {
  flex: 1;
  padding: 20px;
}

.text h2 {
  font-size: 24px;
  color: rgb(46, 46, 46);
  margin-bottom: 10px;
}

.text p {
  font-size: 16px;
  color: #333;
}

.image {
  flex: 1;
  padding: 20px;
}

.image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .text-image {
    flex-direction: column; /* Stack text and image vertically on small screens */
    text-align: center;
  }

  .text-image.reverse {
    flex-direction: column; /* Ensure reverse order still stacks vertically */
  }

  .text, .image {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .text h2 {
    font-size: 20px;
  }

  .text p {
    font-size: 14px;
  }

  .belolgoo img {
    width: 250px;
    height: auto;
  }
}

/* Animation */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Staggered animation for sections */
.section:nth-child(1) {
  animation-delay: 0.3s;
}

.section:nth-child(2) {
  animation-delay: 0.6s;
}

.section:nth-child(3) {
  animation-delay: 0.9s;
}
