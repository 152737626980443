/* AdOrd.css */

.order-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 0.75rem;
  }
  
  .card-title {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
  
  .card-text {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
  
  .list-group-item {
    border: none;
    padding: 0.25rem 0;
  }
  
  .list-group-item p {
    margin: 0;
    font-size: 0.875rem;
  }
  
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  
  .col-md-6 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  