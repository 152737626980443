*{
    margin: 0;
    padding: 0;
}
.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1d1c1c;
    padding: 2rem 6rem;
    height: 20px;
    width: 100%;
    font-weight: 300;
    z-index: 1;
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links a{
    color: white;
    text-decoration: none;
}

.gpt3__navbar-links_logo {
    margin-right: 20rem;
    margin-left: 15%;
}

.gpt3__navbar-links_logo img {
    width: 90px;
    height: 50px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 120px;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #333;
    background: #fff;
    border: 2px solid black;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
 
.gpt3__navbar-menu{
    margin-left: 7rem;
    display: none;
    position: relative;
    z-index: 1;
}

.gpt3__navbar-menu svg{
    cursor: pointer;
}

.gpt3__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: #1d1c1c;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.gpt3__navbar-menu_container p{
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign{
    display: none;
}

.gpt3__navbar-menu_container-links a{
    color: white;
    text-decoration: none;
}


@media screen and (max-width: 1050px){
    .gpt3__navbar-links_container{
        display: none;
    }

    .gpt3__navbar-menu{
        display: flex;
    }

    .gpt3__navbar-links_logo {
        margin-right: 0rem;
    }
}

@media screen and (max-width: 700px){
    .gpt3__navbar{
        padding: 2rem 4rem;
        background-color: black;
    }

    .gpt3__navbar-menu_container{
        background-color: black;
    }


}

@media screen and (max-width: 550px){
    .gpt3__navbar{
        padding: 2rem;
    }

    .gpt3__navbar-sign{
        display: none;
    }

    .gpt3__navbar-menu_container{
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign{
        display: block;
    }
}



.profile{
    font-size: 2em;
    cursor: pointer;
}

.search{
    font-size: 2em;
}

.bag{
    font-size: 1.5em;
}

.search-bar{
    display: flex;
}

.search-bar input{
    padding: 5px 20px;
    border: none;
    outline: none;
    border-bottom: 2px solid black;
    border-radius: 5px;
}


/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown content */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    left: -80px;
  }
  
  /* Dropdown links */
  .dropdown-content a {
    color: black;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  

  .hsss{
    transition: all 0.3s ease;
    cursor: pointer;
  }

.closese{
    cursor: pointer;
    margin-top: 15px;
}

.se{
    cursor: pointer;
}


